<template>
	<div class="mt-3">
		<is-loading
			:loading="loading"
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')"
			:class="loading ? 'mx-auto' : 'w-100'"
			:has-data="!!data.length">
			<template slot="no-data">
				<b-alert
					variant="warning"
					show>
					<i class="fa fa-exclamation-circle" />	{{ translate(`no_${type}_address`) }}
				</b-alert>
			</template>
		</is-loading>
		<template v-if="!loading && !!data.length">
			<template v-if="(data.length === 1 && type === 'recurring') && (!data[0].attributes.default_shipping)">
				<b-row>
					<b-col>
						<b-alert
							variant="warning"
							show>
							{{ translate(`${data[0].attributes.default_billing}_missing_address`) }}
						</b-alert>
					</b-col>
				</b-row>
			</template>
			<b-row>
				<div
					v-for="(item, index) in data"
					:key="index"
					class="mb-4 col-12 col-lg-6">
					<b-card-group
						class="h-100"
						deck>
						<b-card
							:header="getHeader({shipping: item.attributes.default_shipping})"
							:bg-variant="background">
							<template class="mb-4 text-left">
								<b-row>
									<b-col>
										<b>{{ item.attributes.first_name }} {{ item.attributes.last_name }}</b>
										<p class="mb-0 p-0 text-left">
											{{ item.attributes.address }}
										</p>
										<p class="mb-0 p-0 text-left">
											{{ item.attributes.address2 }}
										</p>
										<p class="mb-0 p-0 text-left">
											{{ item.attributes.address3 }}
										</p>
										<p class="mb-0 p-0 text-left">
											{{ item.attributes.address4 }}
										</p>
										<p class="mb-0 p-0 text-left">
											{{ item.attributes.city }}
										</p>
										<p
											v-if="item.attributes.region !== null"
											class="mb-0 p-0 text-left">
											{{ item.attributes.region.name }}, {{ item.attributes.postcode }}
										</p>
										<p
											v-else
											class="mb-0 p-0 text-left">
											{{ item.attributes.postcode }}
										</p>
										<p class="mb-0 p-0 text-left">
											{{ translate(item.attributes.country.code.toLowerCase()) }}
										</p>
										<p class="text-left">
											{{ item.attributes.telephone }}
										</p>
										<p
											v-if="item.attributes.is_pickup_address"
											class="text-left">
											<span class="badge badge-primary">
												{{ translate('pickup_address') }}
											</span>
										</p>
									</b-col>
								</b-row>
								<b-row class="mt-4">
									<b-col>
										<b-button
											variant="link"
											class="text-left px-0 col-xs-12 col-md-auto py-0"
											@click="$emit('edit', item.id, item.attributes.country.code)">
											{{ translate('edit') }}
										</b-button>
										<template>
											<span class="mx-1 d-none d-md-inline-block">|</span>
											<b-button
												variant="link"
												class="text-left px-0 col-xs-12 col-md-auto py-0"
												@click="$emit('delete', item)">
												{{ translate('delete') }}
											</b-button>
										</template>
										<template v-if="showUseForVFillShippingButton(item)">
											<span class="mx-1 d-none d-md-inline-block">|</span>
											<b-button
												variant="link"
												class="text-left px-0 col-xs-12 col-md-auto py-0"
												@click="$emit('recurring', item)">
												{{ translate('recurring') }}
											</b-button>
										</template>
									</b-col>
								</b-row>
							</template>
						</b-card>
					</b-card-group>
				</div>
			</b-row>
		</template>
	</div>
</template>

<script>

import { Countries, Profile } from '@/translations';
import { VALIDATE_PERSONAL_DOCUMENT_COUNTRIES } from '@/settings/AddressBook';

export default {
	name: 'AddressGrid',
	messages: [Countries, Profile],
	props: {
		loading: {
			type: Boolean,
			default: false,
		},
		data: {
			type: Array,
			required: true,
		},
		background: {
			type: String,
			default: () => '',
		},
		type: {
			type: String,
			default: () => 'additional',
			validator: (value) => ['additional', 'recurring'].includes(value),
		},
	},
	methods: {
		getHeader(payload) {
			if (payload.shipping) {
				return this.translate('recurring_shipping');
			}
			return '';
		},
		showUseForVFillShippingButton(item) {
			return !item.attributes.default_shipping
				&& !item.attributes.is_gift_address
				&& (!VALIDATE_PERSONAL_DOCUMENT_COUNTRIES.includes(this.country) || item.attributes.personal_document_number);
		},
	},
};
</script>
<style>
.bottom {
	position: absolute;
	bottom: 0;
	left: 0;
}
</style>
